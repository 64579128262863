import { Type } from "@angular/compiler";
import { Component, HostListener, OnInit, EventEmitter, Output } from "@angular/core";
import { ViewChild, ElementRef } from "@angular/core";
import { FormBuilder, FormGroup, Validators, AbstractControl } from "@angular/forms";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { StepperOrientation } from "@angular/cdk/stepper";

import { BeneficiariesService } from "../../shared/services/beneficiaries.service";
import { ChildrenService } from "../../shared/services/children.service";
import { AngularFirestore } from "@angular/fire/firestore";
import { DependantsService } from "../../shared/services/dependants.service";
import { AddressValidator } from "src/validators/digital";

@Component({
  selector: "app-guardian",
  templateUrl: "./guardian.component.html",
  styleUrls: ["./guardian.component.css"],
})
export class GuardianComponent implements OnInit {
  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  public searchStr: string = "";
  public age;
  address;
  isVertical = false;
  orientation: StepperOrientation = "vertical";
  smallScreen: boolean;
  completed: boolean = false;
  state: string;
  title = "htmltopdf";
  anothername: string;
  residential: string;
  child_address;
  addG;
  listProp = "no"

  showSave = "yes";

  new_form = 'no';
  editing = 'no';
  basic_doc_id;
  basic;
  child_detail;
  retrieve_child_detail

  f_name = ''
  M_name = ''
  l_name = ''
  res_address = ''
  mail_address = ''
  digi_address = ''
  occupation = ''
  occupation2 = '';
  phone;
  occupations: string[] = [
    "Accountant",
    "actor",
    "actress",
    "ambassador",
    "artist",
    "astronaut",
    "astronomer",
    "athlete",
    "attorney",
    "auctioneer",
    "author",
    "babysitter",
    "baker",
    "banker",
    "barber",
    "biologist",
    "blacksmith",
    "bookkeeper",
    "builder",
    "butcher",
    "butler",
    "cardiologist",
    "caregiver",
    "carpenter",
    "cartoonist",
    "CASHIER",
    "CATERER",
    "CHAPLAIN",
    "chauffeur",
    "chef",
    "chemist",
    "clergyman",
    "clergywoman",
    "clerk",
    "coach",
    "cobbler",
    "composer",
    "contractor",
    "cook",
    "coroner",
    "courier",
    "custodian",
    "dancer",
    "dentist",
    "dermatologist",
    "designer",
    "detective",
    "director",
    "disc jockey",
    "diver",
    "doctor",
    "driver",
    "drummer",
    "dry cleaner",
    "ecologist",
    "economist",
    "editor",
    "educator",
    "electrician",
    "engineer",
    "entertainer",
    "entomologist",
    "entrepreneur",
    "executive",
    "exporter",
    "farmer",
    "financier",
    "firefighter",
    "fisherman",
    "flutist",
    "foreman",
    "game designer",
    "gardener",
    "geneticist",
    "geologist",
    "grocer",
    "hairdresser",
    "handyman",
    "hunter",
    "importer",
    "instructor",
    "intern",
    "interpreter",
    "inventor",
    "investigator",
    "jailer",
    "janitor",
    "jester",
    "jeweler",
    "jockey",
    "journalist",
    "judge",
    "laborer",
    "landlord",
    "landscaper",
    "lawyer",
    "lecturer",
    "legal assistant",
    "librarian",
    "lifeguard",
    "linguist",
    "locksmith",
    "magician",
    "maid",
    "manager",
    "marketer",
    "mason",
    "mathematician",
    "mayor",
    "mechanic",
    "messenger",
    "midwife",
    "miner",
    "model",
    "musician",
    "notary",
    "novelist",
    "nun",
    "nurse",
    "operator",
    "ophthalmologist",
    "optician",
    "orderly",
    "painter",
    "paleontologist",
    "paralegal",
    "park ranger",
    "pathologist",
    "pediatrician",
    "percussionist",
    "performer",
    "pharmacist",
    "philanthropist",
    "philosopher",
    "photographer",
    "physician",
    "physicist",
    "pianist",
    "pilot",
    "plumber",
    "poet",
    "police",
    "policeman",
    "policewoman",
    "politician",
    "president",
    "principal",
    "private",
    "private detective",
    "producer",
    "professor",
    "programmer",
    "psychiatrist",
    "psychologist",
    "publisher",
    "radiologist",
    "real estate agent",
    "receptionist",
    "referee",
    "registrar",
    "reporter",
    "representative",
    "researcher",
    "restaurateur",
    "retailer",
    "retiree",
    "sailor",
    "salesperson",
    "saxophonist",
    "scholar",
    "scientist",
    "seamstress",
    "security guard",
    "singer",
    "soldier",
    "statistician",
    "stockbroker",
    "student",
    "surgeon",
    "surveyor",
    "tailor",
    "tax collector",
    "taxi driver",
    "teacher",
    "technician",
    "tennis player",
    "tiler",
    "toolmaker",
    "trader",
    "trainer",
    "translator",
    "travel agent",
    "treasurer",
    "truck driver",
    "tutor",
    "typist",
    "undertaker",
    "usher",
    "valet",
    "veterinarian",
    "violinist",
    "waiter",
    "waitress",
    "warden",
    "weaver",
    "welder",
    "woodcarver",
    "workman",
    "writer",
    "xylophonist",
    "zookeeper",
    "zoologist",
    'Other'
  ];
  relationship = ''
  all_list = [];
  dependants
  organization_name
  items;
  beneficiary;
  relationship2 = '';
  relationships: string[] = [
    'Mother',
    'Step Mother',
    'Mother - In - Law',
    'Sister',
    'Sister - In - Law',
    'Step Sister',
    'Father',
    'Step Father',
    'Father - In - Law',
    'Brother',
    'Step Brother',
    'Brother - In - Law',
    'Cousin',
    'Uncle',
    'Aunt',
    'Grandmother',
    'Grandfather',
    'Great Grandmother',
    'Great Grandfather',
    'Friend',
    'Other Relative',
    'Other'
  ];

  @Output() callParent = new EventEmitter();
  
  constructor(
    private _formBuilder: FormBuilder,
    private breakpointObserver: BreakpointObserver,
    public beneficiary_service: BeneficiariesService,
    public child_service: ChildrenService,
    private db: AngularFirestore,
    public dependants_service: DependantsService,
  ) { }

  ngOnInit() {
    this.fourthFormGroup = this._formBuilder.group({
      f_name: ["", Validators.required],
      M_name: [""],
      l_name: ["", Validators.required],
      phone: ["", Validators.required],
      res_address: [""],
      relationship: [""],
      relationship2: [""],
      beneficiary: [""],
      mail_address: [""],
      digi_address: ['', Validators.compose([Validators.required, AddressValidator.isValid]) ],
      occupation: [""],
      occupation2: [""],
      appoint_guardian: [""],
      child_detail: ["", Validators.required]
    });


    // [this.customFormatValidator()]]

    this.beneficiary_service
      .retrieve_guardian()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
        this.basic = basic
        let data = basic[0];

        console.log("LENGHT:::", this.basic.length)

        if (basic.length > 0) {
          let data = basic[0];
          this.basic_doc_id = data.id

          this.f_name = data.data.f_name
          this.M_name = data.data.M_name
          this.l_name = data.data.l_name
          this.relationship = data.data.relationship
          this.relationship2 = data.data.relationship2
          this.res_address = data.data.res_address
          this.mail_address = data.data.mail_address
          this.digi_address = data.data.digi_address
          this.occupation = data.data.occupation
          this.occupation2 = data.data.occupation2
          this.child_detail = data.data.child_name
          this.beneficiary = data.data.beneficiary
          this.phone = data.data.phone

          console.log("CHECK child_detail:::", this.child_detail[0])
          


        }
      })
    
    
    this.child_service
      .retrieve_child_details()
      .subscribe((basic) => {
        console.log("Retirve children:", basic)
        this.retrieve_child_detail = basic
        let data = this.retrieve_child_detail[0];

        console.log("LENGHT:::", this.retrieve_child_detail.length)

        for (var name of this.retrieve_child_detail) {
          console.log("children_list NAME", name);
          this.all_list.push({
            name: name.data.c_first_name + " " + name.data.c_last_name
          });
        }
        console.log("children_list all_list LISTSS::: ", this.all_list);


        if (basic.length > 0) {
          let data = basic[0];
        


        }
      })
    

    
    this.dependants_service
      .retrieve_dependants()
      .subscribe((basic2) => {
        console.log("CHECK BASIC REQUIR:::", basic2)
        this.dependants = basic2

        for (var name of this.dependants) {
          console.log("dependants NAME", name);
          this.all_list.push({
            name: name.data.d_first_name + " " + name.data.d_last_name
          });
        }
        console.log("dependants all_list LISTSS::: ", this.all_list);


        if (basic2.length > 0) {
          let data = basic2[0];
          this.basic_doc_id = data.id


        }
      })

    console.log("WHAT IN all_list", this.all_list)
  }


  public modelChange(str: string) {

  }

  newProperty2() {

    //this.fourthFormGroup.reset()
    this.showSave = "no"
    this.new_form = 'yes'


    this.f_name = ''
    this.M_name = ''
    this.l_name = ''
    this.res_address = ''
   
    this.relationship = ''
    this.relationship2 = ''
    this.mail_address = ''
    this.digi_address = ''
    this.occupation = ''
    this.occupation2 = ''
    this.child_detail = ''
    this.beneficiary = ''
    this.phone = ''
  }



  edit_form(data) {
    this.new_form = 'yes'
    this.editing = 'yes'
    this.showSave = "no"
    this.beneficiary_service
      .retrieve_guard_edit(data)
      .subscribe((basic) => {
        console.log("CHILD SERVICE:::", basic)
        console.log("DEPEM SERVICE DATA ID:::", basic.id)

        this.basic_doc_id = basic.id



        this.f_name = data.data.f_name
        this.M_name = data.data.M_name
        this.l_name = data.data.l_name
        this.relationship = data.data.relationship
        this.relationship2 = data.data.relationship2
        this.res_address = data.data.res_address
        this.mail_address = data.data.mail_address
        this.digi_address = data.data.digi_address
        this.occupation = data.data.occupation
        this.occupation2 = data.data.occupation2
        this.beneficiary = data.data.beneficiary
        this.phone = data.data.phone


      })
  }

  deleteForm(data) {
    console.log("DELETE VALUE ", data);
    console.log("DELETE ID ", data.id);


    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid


    if (userUid) {
      console.log("USER EXISTS--")
      return new Promise<any>((resolve, reject) => {
        this.db
          .collection("guardian")
          .doc(data.id)
          .delete()
      });

    }
  }

  customFormatValidator() {
    // return (control) => {
    //   const value = control.value;
    //   const validFormat = /^[A-Z]{2}-\d{3,4}-\d{3,4}$/.test(value);

    //   return validFormat ? null : { customFormat: true };
    // };

    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value && typeof control.value === 'string') {
        const valueWithoutSpaces = control.value.replace(/\s/g, ''); // Remove whitespace
        const validFormat = /^[A-Z]{2}-\d{3,4}-\d{3,4}$/.test(valueWithoutSpaces); // Validate format

        return validFormat ? null : { customFormat: true };
      }

      return { customFormat: true };
    };
  }



  save_trust() {
    let formData: any = this.fourthFormGroup.value as {};
    console.log("VALUE ", formData);

    this.beneficiary_service.save_guardian(formData)

    this.new_form = 'no'
    this.callParent.emit("completed10");

  }

  params

  proceed(data) {
    let formData: any = this.fourthFormGroup.value as {};
    console.log("Data ", data);
    console.log("VALUE ", formData);


    this.params = {
      child_name: data,
      f_name: formData.f_name,
      M_name: formData.M_name,
      l_name: formData.l_name,
      relationship: formData.relationship,
      relationship2: formData.relationship2,
      res_address: formData.res_address,
      mail_address: formData.mail_address,
      digi_address: formData.digi_address,
      occupation: formData.occupation,
      occupation2: formData.occupation2,
      beneficiary: formData.beneficiary,
      phone:  formData.phone

    };

    console.log(" this.params ", this.params);

    this.beneficiary_service.save_guardian(this.params)


    this.new_form = 'no'
    this.editing = 'no'

    this.callParent.emit("completed9");

  }


  update(data) {
    let formData: any = this.fourthFormGroup.value as {};
    console.log("VALUE ", formData);
    console.log("Data ", data);

    this.params = {
      child_name: data,
      f_name: formData.f_name,
      M_name: formData.M_name,
      l_name: formData.l_name,
      relationship: formData.relationship,
      relationship2: formData.relationship2,
      res_address: formData.res_address,
      mail_address: formData.mail_address,
      digi_address: formData.digi_address,
      occupation: formData.occupation,
      occupation2: formData.occupation2,
      beneficiary: formData.beneficiary,
      phone: formData.phone

    };

    console.log(" this.params ", this.params);
    console.log(" DOC ID ", this.basic_doc_id);

    this.beneficiary_service.update_guardian(this.basic_doc_id, this.params)

    this.new_form = 'no'
    this.editing = 'no'
    this.callParent.emit("completed10");

  }

  capitalizeFirstLetter(occupation: string): string {
    return occupation.charAt(0).toUpperCase() + occupation.slice(1);
  }
}

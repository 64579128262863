import { Type } from "@angular/compiler";
import { Component, HostListener, OnInit, EventEmitter, Output } from "@angular/core";
import { ViewChild, ElementRef } from "@angular/core";
import { FormBuilder, FormGroup, Validators, AbstractControl } from "@angular/forms";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { StepperOrientation } from "@angular/cdk/stepper";

import { DependantsService } from "../../shared/services/dependants.service";
import { AngularFirestore } from "@angular/fire/firestore";
import { SpousalService } from "../../shared/services/spousal.service";
import { Router } from "@angular/router";
import { AddressValidator } from "src/validators/digital";
import { ChildrenService } from "../../shared/services/children.service";

@Component({
  selector: "app-other-dependants",
  templateUrl: "./other-dependants.component.html",
  styleUrls: ["./other-dependants.component.css"],
})
export class OtherDependantsComponent implements OnInit {
  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  public searchStr: string = "";
  public age;
  address;
  isVertical = false;
  orientation: StepperOrientation = "vertical";
  smallScreen: boolean;
  completed: boolean = false;
  state: string;
  title = "htmltopdf";
  anothername: string;
  residential: string;
  child_address;
  name;
  listProp = "no"

  showSave = "yes";

  new_form = 'no';
  editing = 'no';
  basic_doc_id;
  basic;
  basic2;

  d_first_name = ''
  d_mid_name = ''
  d_last_name = ''
  relationship = ''
  res_address = ''
  mail_address = ''
  digi_address = ''

  digital;
  mail;
  spouse_maiden_name = '';
  spouse_address = '';
  spouse_first_name = '';
  spouse_m_name = '';
  spouse_l_name = '';
  spouse
  spoualList
  all_list = [];
  child_ben;
  spouse_basic;
  bene_instruction;

  hide = 'yes'
  relationship2 = '';
  relationships: string[] = [
    'Mother',
    'Step Mother',
    'Mother - In - Law',
    'Sister',
    'Sister - In - Law',
    'Step Sister',
    'Father',
    'Step Father',
    'Father - In - Law',
    'Brother',
    'Step Brother',
    'Brother - In - Law',
    'Cousin',
    'Uncle',
    'Aunt',
    'Grandmother',
    'Grandfather',
    'Great Grandmother',
    'Great Grandfather',
    'Friend',
    'Other Relative',
    'Other'
  ];


  @Output() callParent = new EventEmitter();
  constructor(
    private _formBuilder: FormBuilder,
    private breakpointObserver: BreakpointObserver,
    public dependant_service: DependantsService,
    private db: AngularFirestore,
    public spousal_service: SpousalService,
    public router: Router,
    public childrenService: ChildrenService,
  ) { }

  ngOnInit() {
    this.fourthFormGroup = this._formBuilder.group({
      d_first_name: ["", Validators.required],
      d_mid_name: [""],
      d_last_name: ["", Validators.required],
      bene_instruction: [""],
      relationship: [""],
      relationship2: [""],
      res_address: [""],
      mail_address: [""],
      digi_address: ['', Validators.compose([Validators.required, AddressValidator.isValid])],
    });

    this.dependant_service
      .retrieve_dependants()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
        this.basic = basic

        let data = basic[0];

        console.log("LENGHT:::", this.basic.length)

        if (basic.length > 0) {
          let data = basic[0];
          this.basic_doc_id = data.id


          this.d_first_name = data.data.d_first_name
          this.d_mid_name = data.data.d_mid_name
          this.d_last_name = data.data.d_last_name
          this.relationship = data.data.relationship
          this.relationship2 = data.data.relationship2
          // this.res_address = data.data.res_address
          // this.mail_address = data.data.mail_address
          // this.digi_address = data.data.digi_address



        }
      })



    this.childrenService
      .retrieve_child()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
        this.child_ben = basic
        let data = basic[0];

        console.log("LENGHT:::", this.basic.length)

        for (var name of this.child_ben) {
          console.log("BEN NAME", name);
          this.all_list.push({
            name: name.data.c_first_name + " " + name.data.c_last_name
          });
        }
        console.log("BENE all_list LISTSS::: ", this.all_list);

      })




    this.spousal_service
      .retrieve_spousal_as_dependant()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
        this.spouse_basic = basic
        let data = basic[0];

        console.log("LENGHT:::", this.basic.length)

        for (var name of this.spouse_basic) {
          console.log("BEN NAME", name);
          this.all_list.push({
            name: name.data.spouse_first_name + " " + name.data.spouse_l_name
          });
        }
        console.log("BENE all_list LISTSS::: ", this.all_list);

      })
  }

  form1() {
    console.log(this.firstFormGroup.value);
  }

  public modelChange(str: string, string) {

  }

  customFormatValidator() {
    // return (control) => {
    //   const value = control.value;
    //   const validFormat = /^[A-Z]{2}-\d{3,4}-\d{3,4}$/.test(value);

    //   return validFormat ? null : { customFormat: true };
    // };

    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value && typeof control.value === 'string') {
        const valueWithoutSpaces = control.value.replace(/\s/g, ''); // Remove whitespace
        const validFormat = /^[A-Z]{2}-\d{3,4}-\d{3,4}$/.test(valueWithoutSpaces); // Validate format

        return validFormat ? null : { customFormat: true };
      }

      return { customFormat: true };
    };
  }


  goSpouse() {

    this.spoualList = "yes";
    console.log("STEP HERE ", this.spoualList);

    // this.router.navigateByUrl("/spousal")
  }

  goSpouse2() {

    this.spoualList = "no";
    console.log("STEP HERE ", this.spoualList);

    // this.router.navigateByUrl("/spousal")
  }

  all_forms() {
    this.completed = true;
    this.state = "done";
    console.log(this.firstFormGroup.value);
    console.log(this.secondFormGroup.value);
    this.firstFormGroup.reset();
    this.secondFormGroup.reset();
  }

  newProperty2() {

    // this.fourthFormGroup.reset()
    this.showSave = "yes"
    this.new_form = 'yes'

    this.d_first_name = ''
    this.d_mid_name = ''
    this.d_last_name = ''
    this.relationship = ''
    this.relationship2 = ''
    this.res_address = ''
    this.mail_address = ''
    this.digi_address = ''
    this.bene_instruction = ''
  }

  otherDependant() {
    this.hide = "no"
  }

  edit_form(data) {
    this.new_form = 'yes'
    this.editing = 'yes'
    this.showSave = "no"
    this.hide = 'no'

    this.dependant_service
      .retrieve_dependants_edit(data)
      .subscribe((basic) => {
        console.log("CHILD SERVICE:::", basic)
        console.log("DEPEM SERVICE DATA ID:::", basic.id)

        this.basic_doc_id = basic.id

        this.d_first_name = basic.data.d_first_name
        this.d_mid_name = basic.data.d_mid_name
        this.d_last_name = basic.data.d_last_name
        this.relationship = basic.data.relationship
        this.relationship2 = data.data.relationship2
        this.res_address = basic.data.res_address
        this.mail_address = basic.data.mail_address
        this.digi_address = basic.data.digi_address
        this.bene_instruction = basic.data.bene_instruction


      })
  }



  save_trust() {
    let formData: any = this.fourthFormGroup.value as {};
    console.log("VALUE ", formData);

    this.dependant_service.save_dependant(formData)

    this.new_form = 'no'
    this.callParent.emit("completed7");

  }


  update() {
    let formData: any = this.fourthFormGroup.value as {};
    console.log("VALUE ", formData);

    this.dependant_service.update_dependant(this.basic_doc_id, formData)

    this.new_form = 'no'
    this.editing = 'no'

    this.callParent.emit("completed7");

  }



  deleteForm(data) {
    console.log("DELETE VALUE ", data);
    console.log("DELETE ID ", data.id);


    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid


    if (userUid) {
      console.log("USER EXISTS--")
      return new Promise<any>((resolve, reject) => {
        this.db
          .collection("dependants")
          .doc(data.id)
          .delete()
      });

    }



  }
}
